.contact-container {
  height: 100%;
  width: 100%;
  margin-bottom: 50px;
}

.contact-title-container {
  position: relative;
  width: 100%;
  height: 40%;
}

.contact-player-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.contact-title {
  position: absolute;
  top: 8%;
  left: 10%;
  color: var(--main-color-5);
  background-color: var(--main-color-1);
  padding: 15px;
  font-family: 'Rethink Sans', sans-serif;
  font-size: 26px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}

.contact-title2 {
  position: absolute;
  top: 25%;
  left: 10%;
  color: var(--main-color-5);
  background-color: var(--main-color-1);
  padding: 15px;
  font-family: 'Rethink Sans', sans-serif;
  font-size: 26px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}

.contact-items-container {
  display: flex;
  flex-direction: column;
  background-color: var(--main-color-5);
  margin-bottom: 126px;
}

.contact-icon {
  height: 100%;
  margin-right: 20px;
}

.contact-item {
  display: flex;
  flex-direction: column;
}

.contact-item-title {
  display: flex;
  align-items: center;
  justify-content: left;
  height: 27px;
  margin-left: 53px;
  margin-top: 79px;
  font-family: var(--font-family);
  font-size: 23px;
  font-weight: 500;
  text-align: left;
}

.contact-subtext {
  margin-left: 100px;
  margin-top: 12px;
  color: var(--main-color-2);
  font-size: 23px;
  font-weight: 500;
  height: 30px;
}

.contact-us-form-container {
  padding-left: 36px;
  padding-right: 36px;
  padding-bottom: 114px;
}

.map-link-container {
  align-items: center;
  justify-content: center;
  height: 500px;
  width: 100%;
  background-color: var(--main-color-1);
}