.about-us-container {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  min-width: 341px;
}

.about-us-title-container {
  position: relative;
  height: 530px;
  width: 100%;
  background-color: var(--main-color-2);
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../assets/tempo_entrance.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.about-us-title {
  height: fit-content;
  width: fit-content;
  margin-top: 120px;
  margin-left: 29px;
  font-family: var(--font-family);
  font-size: 26px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  background-color: var(--main-color-1);
  color: var(--main-color-5);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 22px;
  padding-left: 22px;
}

.about-us-subtitle {
  margin-left: 29px;
  margin-top: 40px;
  margin-right: 30px;
  padding: 10px;
  font-size: 22px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--main-color-5);
}

.about-us-section-2 {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: var(--main-color-5);
}

.about-us-section-2 h1 {
  width: 100%;
  height: 100%;
  padding-top: 59px;
  padding-bottom: 24px;
  padding-left: 37px;
  font-family: var(--font-family);
  font-size: 44px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--main-color-4);
}

.about-us-section-2 p {
  margin: 24px 40px 59px 40px;
  font-family: var(--font-family);
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--main-color-4);
}

.our-facilities-container {
  display: flex;
  flex-direction: column;
  padding: 58px 15vw 48px 15vw;
  background-color: #eee;
}

.our-facilities-title {
  width: 100%;
  height: 100%;
  margin-bottom: 51px;
  font-family: var(--font-family);
  font-size: 40px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--main-color-4);
}

.style2 {
  font-weight: 400;
}

.amenity-description {
  width: 100%;
  font-family: var(--font-family);
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--main-color-4);
  margin-bottom: 42px;
}

.amenities-container {
  width: 100%;
}

.amenity-description .style2 {
  font-weight: 800;
}

.amenity1-img {
  width: 70vw;
  height: 70vw;
  margin-bottom: 42px;
  background-color: var(--main-color-2);
  background-image: url('../assets/tempo_pickleball1.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.amenity2-img {
  width: 70vw;
  height: 70vw;
  margin-bottom: 42px;
  background-color: var(--main-color-2);
  background-image: url('../assets/sauna.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.amenity3-img {
  width: 70vw;
  height: 70vw;
  margin-bottom: 42px;
  background-color: var(--main-color-2);
  background-image: url('../assets/tempo_bar1.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.booknow {
  height: 100%;
  width: 100%;
  background-color: var(--main-color-2);
  /* Use a single .webp file instead of multiple images */
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6)), url('../assets/pickleball_player1.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.booknow-title {
  margin-bottom: 42px;
  font-family: var(--font-family);
  font-size: 3.2em;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--main-color-5);
}

.style2 {
  font-weight: 400;
}

.booknow-description {
  margin-bottom: 42px;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--main-color-5);
}

.booknow-button {
  width: 183px;
  height: 58px;
  margin-bottom: 75px;
  padding: 13px 63px 17px 62px;
  border-radius: 8px;
  border: solid 2px var(--main-color-5);
  background-color: rgba(0,0,0,0.5);
  font-size: 21px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--main-color-5);
}

.amennity {
  display: flex;
  flex-direction: column;
}

@media (min-width: 800px) {
  .amenities-container {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 3vw;
  }

  .amenity1-img {
    width: 100%;
    height: 20vw;
  }

  .amenity2-img {
    width: 100%;
    height: 20vw;
  }

  .amenity3-img {
    width: 100%;
    height: 20vw;
  }

  .amenity-description {
    font-size: 1.6vw;
  }

  @supports (aspect-ratio: 1 / 1) {
    .amenity1-img {
      width: 100%;
      height: auto;
      aspect-ratio: 1 / 1;
    }
  
    .amenity2-img {
      width: 100%;
      height: auto;
      aspect-ratio: 1 / 1;
    }
  
    .amenity3-img {
      width: 100%;
      height: auto;
      aspect-ratio: 1 / 1;
    }
  }

  .booknow {
    padding-left: 15vw;
    padding-right: 35vw;
  }

  .about-us-section-2 {
    padding-left: 15vw;
    padding-right: 15vw;
  }

  .about-us-section-2 h1 {
    padding-left: 0px;
  }

  .about-us-section-2 p {
    padding-left: 0px;
    margin-left: 0px;
    font-size: 1.6vw;
  }

  /* Target headings and paragraphs instead of the entire container */
  .about-us-title,
  .about-us-subtitle {
    margin-left: 15vw;
    margin-right: 15vw;
  }
}