.pricing-container {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  min-width: 341px;
}

.pricing-title-container {
  position: relative;
  height: 530px;
  width: 100%;
  background-color: var(--main-color-2);
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../assets/paddle.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.pricing-title {
  height: fit-content;
  width: fit-content;
  margin-top: 120px;
  margin-left: 29px;
  font-family: var(--font-family);
  font-size: 26px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  background-color: var(--main-color-1);
  color: var(--main-color-5);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 22px;
  padding-left: 22px;
}

.pricing-subtitle {
  margin-left: 29px;
  margin-top: 40px;
  margin-right: 30px;
  padding: 10px;
  font-size: 22px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--main-color-5);
}

.pricing-section-2 {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 58px 9.5vw 48px 9.5vw;
  background-color: var(--secondary-color-2);
}

.pricing-section-2 h1 {
  width: 100%;
  height: 100%;
  font-family: var(--font-family);
  font-size: 48px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--main-color-4);
  margin-bottom: 40px;
}

.pricing-section-2 h1 span {
  font-weight: 500;
}

.pricing-section-2 p {
  margin: 24px 40px 59px 40px;
  font-family: var(--font-family);
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--main-color-4);
}

.our-facilities-container {
  display: flex;
  flex-direction: column;
  padding: 58px 15vw 48px 15vw;
  background-color: #eee;
}

.our-facilities-title {
  width: 75vw;
  height: 100%;
  margin-bottom: 51px;
  font-family: var(--font-family);
  font-size: 40px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--main-color-4);
}

.style2 {
  font-weight: 400;
}

.amenity-description {
  font-family: var(--font-family);
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--main-color-4);
  margin-bottom: 42px;
}

.amenity-description .style2 {
  font-weight: 800;
}

.amenity1 {
  width: 70vw;
  height: 70vw;
  margin-bottom: 42px;
  background-color: var(--main-color-2);
  background-image: url('../assets/tempo_pickleball1.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.amenity2 {
  width: 70vw;
  height: 70vw;
  margin-bottom: 42px;
  background-color: var(--main-color-2);
  background-image: url('../assets/sauna.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.amenity3 {
  width: 70vw;
  height: 70vw;
  margin-bottom: 42px;
  background-color: var(--main-color-2);
  background-image: url('../assets/tempo_bar1.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.booknow {
  padding-left: 52px;
  padding-right: 52px;
  padding-top: 90px;
  height: 100%;
  width: 100%;
  background-color: var(--main-color-2);
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../assets/pickleball_player1.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.booknow-title {
  margin-bottom: 42px;
  font-family: var(--font-family);
  font-size: 3.2em;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--main-color-5);
}

.style2 {
  font-weight: 400;
}

.booknow-description {
  margin-bottom: 42px;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--main-color-5);
}

.pricing-booknow-button {
  width: 30vw;
  white-space: nowrap;
  line-height: 1;
  margin-bottom: 75px;
  padding: 15px;
  border-radius: 8px;
  border: solid 2px var(--main-color-5);
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 21px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--main-color-5);
  transition: all 0.3s ease;
}

.pricing-booknow-button:hover {
  background-color: var(--main-color-5);
  color: var(--main-color-1);
  cursor: pointer;
}

.membership {
  margin-bottom: 31px;
}

.pricing-table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 58px 9.5vw 48px 9.5vw;
  background-color: var(--secondary-color-2);
}

.pricing-table-container h1 {
  width: 100%;
  height: 100%;
  font-family: var(--font-family);
  font-size: 48px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--main-color-4);
  margin-bottom: 40px;
}

.pricing-table-container h1 span {
  font-weight: 500;
}

.pricing-table-container p {
  margin: 24px 40px 59px 40px;
  font-family: var(--font-family);
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--main-color-4);
}

@media (min-width: 800px) {
  .pricing-container {
    height: 100%;
  }

  .pricing-section-2 {
    height: auto;
  }

  .pricing-subtitle {
    margin-left: 15vw;
    margin-right: 15vw;
  }
  .pricing-title {
    margin-top: 15vw;
    margin-left: 15vw;
    margin-right: 15vw;
  }

  .pricing-subtitle {
    margin-bottom: 15vw;
  }

  .memberships-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 4vw;
    width: 100%;
    height: auto;
    background-color: var(--secondary-color-2);
  }

  .membership {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    min-height: 500px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0;
  }

  .card-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 500px;
    padding: 3.5vw;
    box-sizing: border-box;
  }

  .top-half-card-content,
  .bottom-half-card-content {
    flex: 1; /* Allow both halves to grow equally */
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden; /* Prevent content from overflowing */
  }

  .top-half-card-content {
    padding-bottom: 2vw;
    border-bottom: 1px solid #707070; /* Division line */
  }

  /* Add padding to the bottom half */
  .bottom-half-card-content {
    padding-top: 2vw;
  }

  .top-half-card-content .get-started-button {
    margin-top: auto; /* Push the button to the bottom */
    align-self: center;
  }

  .top-half-card-content h3,
  .top-half-card-content .price-box,
  .top-half-card-content .regular-font-style {
    margin-bottom: 1vw;
  }

  .bottom-half-card-content .benefits {
    overflow-y: auto;
  }
}
