.infofooter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;
  background-color: black;
  padding: 31px;
  padding-top: 46px;
}

.infofooterlogo {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 35px;
}

.infofooterlogo svg {
  display: flex;
  fill: var(--main-color-5);
  height: auto;
  width: 110%;
  max-width: 220px;
}

.info-sections-wrapper {
  display: flex;
  flex-direction: column;
}

.infofooterinner {
  margin-left: 6px;
}

.infofooterinner > .menu > a {
  text-decoration: none;
}

.menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 12px;
}

.menu > a {
  flex: 0 0 50%;
  box-sizing: border-box;
  font-weight: 600;
  text-decoration: none;
  color: var(--main-color-5);
  margin-bottom: 22px;
}

.menu-item {
  flex: 0 0 50%;
  box-sizing: border-box;
}

.social-media {
  display: flex;
  flex-direction: row;
  margin-bottom: 34px;
}

.instagram-icon {
  height: 40px;
}

.address,
.contact {
  margin-bottom: 22px;
  color: var(--main-color-5);
}

.contact a {
  color: var(--main-color-5);
}

.contact-us {
  margin-bottom: 22px;
  font-weight: 600;
}

.address a {
  color: var(--main-color-5);
}

.visit-us {
  margin-bottom: 22px;
  font-weight: 600;
}

.actions {
  display: flex;
  gap: 10px;
}

.btn-signin {
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.become-member {
}

.btn-signin {
  background-color: white;
  color: black;
}

@media (min-width: 800px) {
  .infofooter {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6vw;
    padding-left: 15vw;
  }

  .infofooterinner {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-grow: 1;
  }

  .menu {
    flex-direction: column;
    align-items: center;
  }

  .info-sections-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-around;
    gap: 50px;
  }

  .social-media {
    display: none;
  }

  .infofooter * {
    margin: 0;
  }

  .infofooterlogo {
    align-self: flex-start;
    max-width: 165px;
    width: 16.5vw;
  }

  .infofooterlogo svg {
    width: 100%;
    height: auto;
    max-width: none;
  }
}

@media (min-width: 1200px) {
  .infofooterlogo {
    max-width: 220px;
    width: 19.8vw;
  }
}

@media (min-width: 1600px) {
  .infofooterlogo {
    max-width: 275px;
    width: 22vw;
  }
}
