.main-title {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  width: 100%;
  height: 531px;
  background-color: var(--main-color-2);
  background-image: url('../assets/tempo_courts1.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 350px;
}

.main-title .headline {
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: left;
  padding-left: 35px;
  padding-top: 55px;
  padding-right: 6vw;
}

.main-title .headline .title {
  font-size: 26px;
  width: fit-content;
  text-wrap: nowrap;
  background-color: var(--main-color-1);
  padding: 15px 20px 15px 20px;
}

.main-title .headline .subtitle {
  width: fit-content;
  margin-top: 18px;
  padding: 15px 20px;
  font-size: 26px;
  text-wrap: nowrap;
  background-color: var(--main-color-1);
}

.main-title .headline .subtitle2 {
  margin-top: 18px;
  width: fit-content;
  padding: 15px 20px;
  font-size: 26px;
  text-wrap: nowrap;
  background-color: var(--main-color-1);
}

.main-title .full-height {
  width: 100%;
  height: 100%;
}

.main-title .book-button {
  margin-bottom: 70px;
  align-content: flex-end;
  height: fit-content;
  width: fit-content;
  background-color: var(--main-color-1);
  border: none;
  padding: 15px 35px;
  border-radius: 8px;
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.main-title .book-button:hover {
  color: var(--main-color-1);
  background-color: var(--main-color-5);
}

.image-one {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-section {
  background-color: #EEEEEE;
  color: #363636;
}

.mission-section {
  background-color: #363636;
  color: #FFFFFF;
}

.our-mission {
  padding-left: 31px;
  padding-right: 30px;
  padding-top: 80px;
  padding-bottom: 64px;
  background-color: var(--main-color-2);
}

.our-mission-title {
  padding-bottom: 63px;
  font-family: var(--font-family);
  font-size: 40px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.our-mission p {
  font-family: var(--font-family);
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--main-color-5);
}

.our-mission-logo {
  display: none;
}

.landing-section-2 {
  padding-left: 31px;
  padding-right: 30px;
  padding-top: 80px;
  padding-bottom: 64px;
  background-color: var(--secondary-color-2);
}

.landing-section-2-title {
  padding-bottom: 35px;
  font-family: var(--font-family);
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
}

.landing-section-2 p {
  font-size: 21px;
  font-family: var(--font-family);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--main-color-4);
  margin-bottom: 40px;
}

.landing-section-2-first-photo {
  height: 100%;
  width: 100%;
  padding: 31px;
  background-color: var(--main-color-2);
  background-image: url('../assets/padel_ball.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 35px;
}

.landing-section-2-first-photo p {
  padding: 33px;
  font-family: var(--font-family);
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
  opacity: 0.85;
  background-color: #fff;
  margin: 0;
}

.landing-section-2-second-photo {
  height: 100%;
  width: 100%;
  padding: 31px;
  background-color: var(--main-color-2);
  background-image: url('../assets/tempo_player1.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.landing-section-2-second-photo p {
  padding: 33px;
  font-family: var(--font-family);
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
  opacity: 0.85;
  background-color: #fff;
  margin: 0;
}

@media (min-width: 800px) {
  .main-title {
    display: flex;
    flex-direction: column;
    color: #ffffff;
    width: 100%;
    height: calc(100%- (70px + 1vh));
    background-color: var(--main-color-2);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 350px;
  }

  .main-title .headline {
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: left;
    padding-left: 35px;
    padding-top: 55px;
    padding-right: 6vw;
  }

  .landing-section-2 {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    display: flex;
  }

  .landing-section-2-1 {
    display: flex;
    flex-direction: column;
    margin-right: 5vw;
    width: 100%;
  }

  .landing-section-2-1 b {
    font-weight: 800;
  }

  .landing-section-2-1 p {
    margin-left: 10vw;
  }

  .landing-section-2-title {
    text-wrap: nowrap;
    margin-left: 10vw;
  }

  .landing-section-2 p {
    font-size: calc(10px + 0.8vw);
    max-width: 320px;
  }

  .landing-section-2-first-photo {
    display: flex;
    flex-direction: column;
    height: 24vw;
    width: 100%;
    margin-bottom: 0px;
    margin-right: 5vw;
    justify-content: flex-end;
  }

  .landing-section-2-second-photo {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 24vw;
    width: 100%;
  }

  .landing-section-2-first-photo p {
    display: flex;
    align-items: flex-end;
    font-size: calc(1.3vw);
    max-height: 100%;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    padding: 2vw;
  }

  .landing-section-2-second-photo p {
    display: flex;
    align-items: flex-end;
    font-size: calc(1.3vw);
    max-height: 100%;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    padding: 2vw;
  }

  .our-mission {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: calc(18vh + 18vw);
    padding: 0px;
  }

  .our-mission-title {
    margin-top: 6%;
    margin-left: 60px;
  }

  .our-mission-title p {
    margin-top: 20px;
    font-size: calc(8px + 0.8vw);
    max-width: 400px;
    min-width: 250px;
    margin-right: 7vw;
  }

  .our-mission-logo {
    align-self: flex-end;
    display: flex;
    flex-grow: 0; 
    flex-shrink: 0;
    flex-basis: auto;
    margin-right: 5vw;
    height: calc(14vw + 14vh);
    width: calc(14vw + 14vh);;
    background-color: transparent;
    background-image: url('../assets/tempo_isotipo_small.webp');
    background-image: -webkit-image-set(url('../assets/tempo_isotipo_small.webp') 1x,
        url('../assets/tempo_isotipo_medium.webp') 2x,
        url('../assets/tempo_isotipo_large.webp') 3x);
    background-image: image-set(url('../assets/tempo_isotipo_small.webp') 1x,
        url('../assets/tempo_isotipo_medium.webp') 2x,
        url('../assets/tempo_isotipo_large.webp') 3x);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
  }
}